$imageIds: '1517021897933-0e0319cfbc28', '1533903345306-15d1c30952de',
  '1545243424-0ce743321e11', '1531306728370-e2ebd9d7bb99';

$bp-md: 550px;
$bp-lg: 1100px;

:root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
}

.page-content {
  display: flex;
  justify-content: center;
  padding: 1rem;
  // max-width: 1024px;

  margin: 0 auto;
  font-family: var(--font-sans);

  @media (min-width: $bp-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $bp-lg) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 0 1rem;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  // height: 100%;
  text-align: center;
  color: whitesmoke;
  background-color: whitesmoke;
  animation: card-fade 4s 1s ease forwards;
  opacity: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);

  // @media (min-width: $bp-md) {
  //   height: 350px;
  // }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    background-size: cover;
    background-position: 0 0;
    transition: transform calc(var(--d) * 1.5) var(--e);
    pointer-events: none;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.009) 11.7%,
      hsla(0, 0%, 0%, 0.034) 22.1%,
      hsla(0, 0%, 0%, 0.072) 31.2%,
      hsla(0, 0%, 0%, 0.123) 39.4%,
      hsla(0, 0%, 0%, 0.182) 46.6%,
      hsla(0, 0%, 0%, 0.249) 53.1%,
      hsla(0, 0%, 0%, 0.32) 58.9%,
      hsla(0, 0%, 0%, 0.394) 64.3%,
      hsla(0, 0%, 0%, 0.468) 69.3%,
      hsla(0, 0%, 0%, 0.54) 74.1%,
      hsla(0, 0%, 0%, 0.607) 78.8%,
      hsla(0, 0%, 0%, 0.668) 83.6%,
      hsla(0, 0%, 0%, 0.721) 88.7%,
      hsla(0, 0%, 0%, 0.762) 94.1%,
      hsla(0, 0%, 0%, 0.79) 100%
    );
    transform: translateY(-50%);
    transition: transform calc(var(--d) * 2) var(--e);
  }

  @each $id in $imageIds {
    $i: index($imageIds, $id);

    &:nth-child(#{$i}):before {
      background: rgb(0, 81, 90);
    }
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;

  > * + * {
    margin-top: 1rem;
  }

  p {
    font-size: 1rem;
  }
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.copy {
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.content p {
  font-size: 1.4rem;
}

.btn {
  cursor: pointer;
  margin-top: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: none;

  &:hover {
    background-color: lighten(black, 10%);
  }

  &:focus {
    outline: 1px dashed yellow;
    outline-offset: 3px;
  }
}

@media (max-width: 1100px) {
  .page-content {
    flex-direction: column;

    .card {
      margin: 1rem 0;
    }
  }
}

@media (hover: hover) and (min-width: $bp-md) {
  .card:after {
    transform: translateY(0);
  }

  .content {
    transform: translateY(calc(100% - 4.5rem));

    > *:not(.title) {
      opacity: 0;
      transform: translateY(1rem);
      transition: transform var(--d) var(--e), opacity var(--d) var(--e);
    }
  }

  .card:hover,
  .card:focus-within {
    align-items: center;

    &:before {
      transform: translateY(-4%);
    }
    &:after {
      transform: translateY(-50%);
    }

    .content {
      transform: translateY(0);

      > *:not(.title) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: calc(var(--d) / 8);
      }
    }
  }

  .card:focus-within {
    &:before,
    &:after,
    .content,
    .content > *:not(.title) {
      transition-duration: 0s;
    }
  }
}

@keyframes card-fade {
  100% {
    opacity: 1;
  }
}

a {
  text-decoration: none;
  color: white;
  font-size: 0.7rem;
}
